.grid.swiper .swiper-wrapper {
  flex-wrap: nowrap;

}

.swiper {
  user-select: none;
}

img.swiper-lazy {
  transition: .2s ease;
}

img.swiper-lazy:not(.swiper-lazy-loaded) {
  opacity: 0;
}

.swiper-button-disabled {
  opacity       : .4;
  pointer-events: none;
}

.swiper-slide {
  align-self: stretch;
  height    : auto;
}