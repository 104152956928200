@font-face {
  font-family: 'Hauora';
  font-weight: normal;
  font-style : normal;
  src        : url('./Hauora-Regular.woff2');
  src        : local('Hauora Regular'), local('Hauora-Regular'),
    url('./Hauora-Regular.otf') format('otf'),
    url('./Hauora-Regular.woff2') format('woff2'),
    url('./Hauora-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Hauora';
  font-weight: bold;
  font-style : normal;
  src        : url('./Hauora-Bold.woff2');
  src        : local('Hauora Bold'), local('Hauora-Bold'),
    url('./Hauora-Bold.otf') format('otf'),
    url('./Hauora-Bold.woff2') format('woff2'),
    url('./Hauora-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Hauora';
  font-weight: 800;
  font-style : normal;
  src        : url('./Hauora-ExtraBold.woff2');
  src        : local('Hauora ExtraBold'), local('Hauora-ExtraBold'),
    url('./Hauora-ExtraBold.otf') format('otf'),
    url('./Hauora-ExtraBold.woff2') format('woff2'),
    url('./Hauora-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Hauora';
  font-weight: 100;
  font-style : normal;
  src        : url('./Hauora-ExtraLight.woff2');
  src        : local('Hauora ExtraLight'), local('Hauora-ExtraLight'),
    url('./Hauora-ExtraLight.otf') format('otf'),
    url('./Hauora-ExtraLight.woff2') format('woff2'),
    url('./Hauora-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Hauora';
  font-weight: 300;
  font-style : normal;
  src        : url('./Hauora-Light.woff2');
  src        : local('Hauora Light'), local('Hauora-Light'),
    url('./Hauora-Light.otf') format('otf'),
    url('./Hauora-Light.woff2') format('woff2'),
    url('./Hauora-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Hauora';
  font-weight: 500;
  font-style : normal;
  src        : url('./Hauora-Medium.woff2');
  src        : local('Hauora Medium'), local('Hauora-Medium'),
    url('./Hauora-Medium.otf') format('otf'),
    url('./Hauora-Medium.woff2') format('woff2'),
    url('./Hauora-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Hauora';
  font-weight: 600;
  font-style : normal;
  src        : url('./Hauora-SemiBold.woff2');
  src        : local('Hauora SemiBold'), local('Hauora-SemiBold'),
    url('./Hauora-SemiBold.otf') format('otf'),
    url('./Hauora-SemiBold.woff2') format('woff2'),
    url('./Hauora-SemiBold.ttf') format('truetype');
}