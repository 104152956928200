.fancybox-custom-modal {
  color: inherit;

  .fancybox__content {
    color: inherit;
  }

  .fancybox__slide {
    padding: 16px;

  }
}