@import 'functions';

:root {
  // theme colors
  --primary-rgb   : #{cl(#F16628)};
  --sec-rgb       : #{cl(#BD48DA)};
  --blue-rgb      : #{cl(#199AFC)};
  --red-rgb       : #{cl(#c0392b)};
  --green-rgb     : #{cl(#319801)};
  --purple-rgb    : #{cl(#8B56DF)};
  --yellow-rgb    : #{cl(#f39c12)};
  --whatsapp-rgb  : #{cl(#26D044)};
  --telegramm-rgb : #{cl(#33AADE)};
  --youtube-rgb   : #{cl(#f00)};
  --vk-rgb        : #{cl(#07F)};

  // colors
  --bg1-rgb     : #{cl(#ededed)};
  --bg2-rgb     : #{cl(#F0F1F5)};
  --bg3-rgb     : #{cl(#fff)};
  --default-rgb : #{cl(#121212)};
  --white-rgb   : #{cl(#fff)};
  --black-rgb   : #{cl(#000)};

  // variables
  --bg1    : rgb(var(--bg1-rgb));
  --bg2    : rgb(var(--bg2-rgb));
  --bg3    : rgb(var(--bg3-rgb));
  --default: rgb(var(--default-rgb));

  // font
  --font-base: 'Inter', arial, helvetica, sans-serif;
  --font-alt : 'Hauora', arial, helvetica, sans-serif;

}

body {
  display       : flex;
  flex-direction: column;
  min-height    : 100vh;
  background    : theme('colors.l3');
  font-family   : var(--font-base);
  color         : theme('colors.default');

  &.menu-opened {
    overflow: hidden;
  }
}

.loader {
  position       : fixed;
  inset          : 0;
  display        : flex;
  align-items    : center;
  justify-content: center;
  z-index        : theme('zIndex.7');
  background     : theme('colors.l3');
  transition     : .25s;
}

body.loaded .loader {
  opacity       : 0;
  pointer-events: none;
}

[data-theme="dark"] {
  --bg1-rgb     : #{cl(#181818)};
  --bg2-rgb     : #{cl(#282828)};
  --bg3-rgb     : #{cl(#323232)};
  --default-rgb : #{cl(#fff)};

}

@layer components {
  .active-enabled:not(:is(:is(.toggle-active, .tab-active, .active) .active-enabled)) {
    display: none !important;
  }

  .active-disabled:is(:is(.toggle-active, .tab-active, .active) .active-disabled) {
    display: none !important;
  }

  .is-active-disabled:is(.toggle-active, .active) {
    display: none;
  }

  .is-active-enabled:not(:is(.toggle-active, .active)) {
    display: none;
  }

  .absolute-fit {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
  }

  .hover-underline {
    text-underline-offset: 4px;

    @media(hover) {
      &:hover {
        text-decoration: underline;
        opacity        : 1;
      }
    }
  }

  .sign {
    width    : max-content;
    max-width: 100%;

    // -webkit-text-fill-color: rgba(0, 0, 0, 0);
    // background             : linear-gradient(90deg, #6557FF 0%, #AA3FFF 29.43%, #AA3FFF 68.23%, #F8522E 100%);
    // background-clip        : text;
    font-family   : var(--font-alt);
    letter-spacing: -0.022em;
    font-weight   : 600;

    @media(max-width:theme('screens.sm')) {
      font-size: 14px;
    }

  }

  .title {
    font-family   : var(--font-alt);
    font-size     : 36px;
    line-height   : 1.3;
    letter-spacing: -0.022em;

    @media(max-width:theme('screens.md')) {
      font-size: 28px;
    }

    @media(max-width:theme('screens.sm')) {
      font-size: 24px;

      br {
        display: none;
      }
    }

    @media(max-width:theme('screens.xs')) {
      font-size: 20px;
    }
  }

  .section-title {
    margin-bottom: 62px;

    @media(max-width:theme('screens.sm')) {
      margin-bottom: 42px;
    }
  }

  .section-padding {
    padding-top   : 72px;
    padding-bottom: 72px;

    @media(max-width:theme('screens.lg')) {
      padding-top   : 58px;
      padding-bottom: 58px;
    }

    @media(max-width:theme('screens.sm')) {
      padding-top   : 40px;
      padding-bottom: 40px;
    }
  }

  .slider-pagination {
    display        : flex;
    justify-content: center;
    align-items    : center;
    gap            : 30px;

    @media(max-width:theme('screens.lg')) {
      gap: 15px;
    }

    .swiper-pagination-bullet {
      width        : 0.5em;
      height       : 0.5em;
      border-radius: 50%;
      background   : theme('colors.default / 10%');
      position     : relative;

      &::before {
        content : '';
        position: absolute;
        inset   : -15px;

        @media(max-width:theme('screens.lg')) {
          inset: -7.5px;

        }
      }

      @media(hover) {
        &:hover {
          background: theme('colors.default / 15%');

        }
      }

      &:is(&-active) {
        background: theme('colors.primary');
      }
    }
  }
}

.header {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  padding-top    : 5px;
  padding-bottom : 5px;
  border-bottom  : 1px solid theme('colors.default / 15%');
  overflow       : hidden;

  @media(max-width:theme('screens.xl')) {
    background: theme('colors.l3 / 99%');
    z-index   : theme('zIndex.1');
    position  : sticky;
    top       : 0;
  }
}

.nav {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  z-index        : theme('zIndex.1');
  position       : sticky;
  top            : 0;

  &:not(:is(.scroll-top &)) {
    background: theme('colors.l3 / 99%');
  }

  &-item {
    padding-top   : 24px;
    padding-bottom: 24px;
    line-height   : 1.15;


    &::before {
      content : '';
      position: absolute;
      inset   : 0 -50%;
    }
  }
}

.upper-3d {
  transform          : translateZ(20px) scale(.98);
  transform-style    : preserve-3d;
  backface-visibility: hidden;
}

.started {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  transform-style: preserve-3d;
  padding-top    : 64px;
  padding-bottom : 130px;
  background     : theme('colors.l3');


  @media(max-width:theme('screens.xl')) {
    flex-direction: column;
    align-items   : stretch;
    gap           : 50px;
    padding-bottom: 20px;
    overflow      : hidden;
  }

  @media(max-width:theme('screens.sm')) {
    gap           : 20px;
    padding-top   : 40px;
    padding-bottom: 0;
  }

  @media(max-width:theme('screens.xs')) {
    padding-top: 32px;

  }

  &-content {
    @media(max-width:theme('screens.xl')) {
      order: 10;
    }
  }

  &-title {
    font-size     : 36px;
    line-height   : 1.3;
    letter-spacing: -0.022em;
    font-family   : var(--font-alt);

    @media(max-width:theme('screens.xl')) {
      font-size: 24px;
    }

    @media(max-width:theme('screens.sm')) {
      font-size: 20px;
    }
  }

  &-image-wrapper {
    width          : 600px;
    position       : relative;
    transform-style: preserve-3d;
    perspective    : 1000px;
    max-width      : 100%;

    @apply ratio;

    @media(max-width:theme('screens.xxl')) {
      width: 500px;
    }

    @media(max-width:theme('screens.xl')) {
      order       : -1;
      margin-left : auto;
      margin-right: auto;
    }

    &::before {
      --tw-ratio-padding: calc(100% * 598 / 600)
    }
  }

  &-image {
    // temp
    will-change: transform;

    &,
    &-front,
    &-back {
      position           : absolute;
      left               : 0;
      top                : 0;
      width              : 100%;
      height             : 100%;
      backface-visibility: hidden;
      transform-style    : preserve-3d;
    }

    &-front {
      // temp
    }

    &-back {
      transform: rotateY(180deg);
    }

    &-background {
      position           : absolute;
      left               : 0;
      top                : 0;
      width              : 100%;
      height             : 100%;
      backface-visibility: hidden;
      border-radius      : 48px;
      overflow           : hidden;
      transform-style    : preserve-3d;
    }

    &-dude {
      position           : absolute;
      transform-style    : preserve-3d;
      transform          : translateZ(50px) scale(0.95);
      backface-visibility: hidden;
      max-width          : none;
      z-index            : 2;
    }

    &-card {
      position           : absolute;
      width              : 214px;
      height             : 150px;
      box-shadow         : 0 20px 40px 0 #0000000D, 0 20px 40px 0 #0000000D;
      border-radius      : 24px;
      color              : theme('colors.black');
      letter-spacing     : -0.035em;
      transform-style    : preserve-3d;
      transform          : translateZ(100px) scale(0.9);
      backface-visibility: hidden;

      @media(max-width:theme('screens.xxl')) {
        width    : 160px;
        height   : 100px;
        transform: translateZ(100px);
      }

      &-1 {
        transform: translateZ(100px) rotateY(5deg) scale(0.9);
      }

      &-2 {
        transform: translateZ(100px) rotateY(-5deg) scale(0.9);
      }

      &-inner {
        transform-style    : preserve-3d;
        backface-visibility: hidden;
        padding            : 32px 28px;
        position           : absolute;
        inset              : 0;
        display            : flex;
        flex-direction     : column;
        justify-content    : center;

        @media(max-width:theme('screens.xxl')) {
          padding: 10px;
        }
      }
    }

    &-attr {
      position           : absolute;
      backface-visibility: hidden;
    }

    &-front &-dude {
      bottom: 0;
      width : calc(100% * 609 / 600);
      left  : calc(100% * 47 / 600);
    }

    &-front &-attr-1 {
      width    : calc(100% * 250 / 600);
      right    : calc(-1 * 100% * 55 / 600);
      top      : calc(-1 * 100% * 35 / 598);
      transform: translateZ(50px) translateX(-8%) scale(0.95);

      transform-origin: center 80px;
    }

    &-front &-card-1 {
      bottom: calc(100% * 40 / 598);
      right : calc(100% * 414 / 600);

      @media(max-width:theme('screens.xxl')) {
        right: auto;
        left : 0;
      }

      @media(max-width:theme('screens.sm')) {
        left: 2%;
      }
    }

    &-front &-card-2 {
      top : calc(100% * 150 / 600);
      left: calc(100% * 400 / 600);

      @media(max-width:theme('screens.xxl')) {
        left : auto;
        right: 0;

      }

      @media(max-width:theme('screens.sm')) {
        right: 2%;
      }
    }

    &-back &-dude {
      bottom: 0;
      width : calc(100% * 590 / 600);
      right : calc(100% * 18 / 600);
    }

    &-back &-card-1 {
      bottom: calc(100% * 40 / 598);
      right : calc(100% * 414 / 600);

      @media(max-width:theme('screens.xxl')) {
        right: auto;
        left : 0;
      }

      @media(max-width:theme('screens.sm')) {
        left: 2%;
      }
    }

    &-back &-card-2 {
      top : calc(100% * 234 / 598);
      left: calc(100% * 400 / 600);

      @media(max-width:theme('screens.xxl')) {
        left : auto;
        right: 0;

      }

      @media(max-width:theme('screens.sm')) {
        right: 2%;
      }
    }
  }

  &-form {
    background   : theme('colors.l2');
    border-radius: 24px;
    padding      : 40px 32px 32px;
    width        : 528px;

    @media(max-width:theme('screens.xl')) {
      width: 100%;
    }

    @media(max-width:theme('screens.sm')) {
      padding      : 20px 15px;
      width        : calc(100% + 30px);
      margin-left  : -15px;
      border-radius: 0;
      background   : theme('colors.l3');
    }

    &-free {
      height        : 34px;
      display       : flex;
      align-items   : center;
      text-transform: uppercase;
      background    : linear-gradient(90deg, theme('colors.sec') 0%, theme('colors.primary') 100%);
      color         : #fff;
      font-size     : 18px;
      font-weight   : 600;
      border-radius : 6px;
    }
  }
}

.advantages {
  background: url("../img/advantages-bg-min.png") no-repeat left bottom, #121212;
  background: #1C223A;
  color     : #fff;


  &-item {
    padding       : 48px 40px 60px;
    border-radius : 36px;
    display       : flex;
    flex-direction: column;

    @media(max-width:theme('screens.lg')) {
      flex-direction: row;
      gap           : 20px;
    }

    @media(max-width:theme('screens.sm')) {
      padding      : 32px 15px;
      border-radius: 16px;
    }
  }

  &-grid {
    display              : grid;
    grid-template-columns: 382fr 418fr 350fr;
    gap                  : 40px;

    @media(max-width:theme('screens.lg')) {
      display       : flex;
      flex-direction: column;
      gap           : 20px;
    }
  }
}

.service {
  // background: url("../img/service-bg-min.png") no-repeat bottom, theme('colors.l3');
  background: theme('colors.l2');

  // @media(max-width:theme('screens.lg')) {
  //   background: url("../img/questions-bg-min.png") no-repeat center / cover, theme('colors.l3');
  // }

  &-item {
    border-radius: 36px;
    background   : theme('colors.l3');
    padding      : 12px 32px 40px;


    @media(max-width:theme('screens.md')) {
      padding-top: 24px;
    }

    @media(max-width:theme('screens.sm')) {
      border-radius: 16px;
      padding      : 24px 15px;
      box-shadow   : 0 0 15px theme('colors.purple / 10%');
    }
  }
}

.package {
  background: url("../img/package-lines.svg") no-repeat right / cover, #1C223A;
  color     : #fff;
  position  : relative;
  overflow  : hidden;

  @media(min-width:theme('screens.lg')) {
    padding-bottom: 0;

  }

  @media(max-width:theme('screens.sm')) {
    background: #1C223A;
  }



  &-grid {
    display              : grid;
    grid-template-columns: repeat(6, 1fr);
    gap                  : 48px;
    padding-bottom       : 140px;
    mask-image           :
      linear-gradient(270.01deg,
        rgba(217, 217, 217, 0) 0%,
        rgba(217, 217, 217, 0.2) 17.31%,
        rgba(217, 217, 217, 0.8721) 34.69%,
        #D9D9D9 65.35%,
        rgba(217, 217, 217, 0.2) 82.7%,
        rgba(217, 217, 217, 0) 99.99%);

    @media(max-width:theme('screens.lg')) {
      grid-template-columns: 1fr 1fr 1fr;
      padding-bottom       : 0;
      mask                 : none;
    }

    @media(max-width:theme('screens.md')) {
      gap: 20px;
    }

    @media(max-width:theme('screens.sm')) {
      grid-template-columns: 1fr;
    }
  }

  &-item {
    height         : 232px;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
    border-radius  : 16px;
    background     : theme('colors.l3 / 20%');
    position       : relative;
    gap            : 20px;

    @media(max-width:theme('screens.sm')) {
      height        : auto;
      padding-top   : 20px;
      padding-bottom: 20px;

      flex-direction: row;
    }

    &::before {
      content      : '';
      position     : absolute;
      width        : 100%;
      height       : 100%;
      left         : 0;
      top          : calc(100% + 46px);
      background   : inherit;
      border-radius: inherit;
      mask         : linear-gradient(0deg, rgba(18, 18, 18, 0) 50%, #121212 100%);

      @media(max-width:theme('screens.lg')) {
        display: none;
      }
    }

    &-active {
      background: theme('colors.l3');
      color     : theme('colors.default');
    }

    &-icon {
      box-shadow   : 0 10px 30px 0 rgba(0, 0, 0, 0.122);
      border-radius: 12px;
    }

    &-active &-icon {
      color     : theme('colors.primary');
      box-shadow: 0 10px 30px 0 theme('colors.primary / 80%');

    }
  }
}

.digits {
  background: url("../img/digits-bg-min.png") no-repeat bottom, #1C223A;
  color     : #fff;

  // @media(max-width:theme('screens.sm')) {
  //   background: url("../img/questions-bg-min.png") no-repeat center / cover, #1C223A;
  // }

  &-item {
    display       : flex;
    flex-direction: column;
    align-items   : center;
    text-align    : center;
    padding-top   : 72px;
    padding-bottom: 40px;
    border-radius : 36px;
    position      : relative;
    overflow      : hidden;

    @media(max-width:theme('screens.lg')) {
      padding-top: 32px;
    }

    @media(max-width:theme('screens.sm')) {
      border-radius: 16px;
    }

    &::before {
      content : '';
      position: absolute;
      left    : 0;
      bottom  : 0;
      width   : 100%;
      height  : 4px;

      background: theme('colors.primary');
    }

    &-value {
      font-size    : 42px;
      font-family  : var(--font-alt);
      margin-bottom: 55px;
      line-height  : 1;


      @media(max-width:theme('screens.lg')) {
        margin-bottom: 32px;
      }

      @media(max-width:theme('screens.sm')) {
        font-size    : 32px;
        margin-bottom: 20px;
      }
    }
  }
}

.questions {
  background: theme('colors.l2');


  &-item {
    border-radius: 36px;
    background   : theme('colors.l3');
    transition   : border-radius .15s ease;
    overflow     : hidden;

    &:not(.toggle-active) {
      border-radius: 45px;
    }

    @media(max-width:theme('screens.sm')) {
      box-shadow: 0 0 15px theme('colors.purple / 10%');
    }
  }
}

.cases {
  &-slider {
    @media(max-width:1500px) {
      // temp

      .slider-next,
      .slider-prev {
        display: none;
      }
    }
  }

  &-item {
    border-radius: 36px;
    border       : 1px solid theme('colors.default / 15%');
    margin-bottom: 8px;
    position     : relative;
    background   : theme('colors.l3');

    @media(max-width:theme('screens.sm')) {
      border-radius: 16px;
    }

    &-header {
      background:
        url("../img/case-header-min.png") no-repeat right,
        #1C223A;
      color          : #fff;
      display        : flex;
      align-items    : center;
      justify-content: space-between;
      height         : 152px;
      padding-left   : 34px;
      padding-right  : 34px;

      border-start-end-radius  : inherit;
      border-start-start-radius: inherit;

      @media(max-width:theme('screens.sm')) {
        height       : 120px;
        padding-left : 15px;
        padding-right: 15px;
      }

      @media(max-width:theme('screens.xs')) {
        height: 100px;
      }
    }

    &-content {
      padding: 10px 32px 40px;

      @media(max-width:theme('screens.sm')) {
        padding: 10px 15px 20px;
      }
    }

    &::before {
      content      : '';
      position     : absolute;
      bottom       : -8px;
      height       : 100px;
      width        : calc(100% - 32px);
      left         : 16px;
      border       : inherit;
      border-radius: inherit;
      background   : inherit;
      z-index      : -1;
      border-top   : none;
    }
  }
}

.subscribe {
  padding      : 48px;
  background   : url("../img/subscribe-min.png") no-repeat right bottom, #1C223A;
  color        : #fff;
  border-radius: 36px;
  display      : flex;
  align-items  : center;

  @media(max-width:theme('screens.lg')) {
    flex-direction: column;
    gap           : 40px;

  }

  @media(max-width:theme('screens.sm')) {
    padding      : 20px 15px;
    border-radius: 16px;
    gap          : 20px;
  }

  &-form {
    width      : 620px;
    max-width  : 100%;
    flex-shrink: 0;
  }

  &-dude {
    margin-left  : auto;
    padding      : 36px;
    border-radius: 24px;
    box-shadow   : 0 20px 40px 0 rgba(0, 0, 0, 0.051);
    background   : theme('colors.l3');
    color        : theme('colors.default');
    width        : 400px;
    display      : flex;
    align-items  : center;
    gap          : 28px;
    position     : relative;
    z-index      : 2;

    @media(max-width:theme('screens.lg')) {
      width  : 100%;
      order  : -1;
      padding: 20px;

    }

    @media(max-width:theme('screens.sm')) {
      border-radius: 16px;
      gap          : 18px;
    }

    &::before {
      content      : '';
      position     : absolute;
      background   : theme('colors.l3 / 50%');
      width        : calc(100% - 30px);
      height       : 100%;
      left         : 15px;
      top          : 0;
      transform    : rotateZ(-5deg);
      border-radius: inherit;
      z-index      : -1;

      @media(max-width:theme('screens.lg')) {
        display: none;
      }
    }

    &-avatar {
      background:
        linear-gradient(153.43deg, rgba(255, 160, 115, 0) 48.11%, theme('colors.primary / 40%') 100%),
        theme('colors.primary / 20%');
      border-radius: 50%;
      position     : relative;

      &::after {
        content      : '';
        position     : absolute;
        inset        : -4px;
        border-radius: inherit;
        border       : 1px solid theme('colors.primary / 60%');
      }
    }
  }
}

.pray {
  position: relative;
  overflow: hidden;
  background:
    url("../img/pray-bg-min.png") no-repeat right bottom, url("../img/pray-lines.svg") no-repeat right bottom,
    #1C223A;
  color      : #fff;
  display    : flex;
  align-items: center;

  @media(max-width:theme('screens.lg')) {
    flex-direction: column;
    align-items   : stretch;
  }

  // @media(max-width:theme('screens.sm')) {
  //   background: url("../img/questions-bg-min.png") no-repeat center / cover, #1C223A;
  // }

  &-lines {
    position      : absolute;
    right         : 0;
    bottom        : 0;
    pointer-events: none;
  }

  &-content {
    margin-bottom: 30px;
  }

  &-form {
    width        : 438px;
    flex-shrink  : 0;
    margin-left  : auto;
    padding      : 40px 40px 32px;
    background   : theme('colors.l3');
    color        : theme('colors.default');
    border-radius: 32px;

    @media(max-width:theme('screens.lg')) {
      width: 100%;
    }

    @media(max-width:theme('screens.sm')) {
      border-radius: 16px;
      padding      : 32px 20px;
    }
  }
}

.contacts {
  position: relative;

  @media(max-width:theme('screens.lg')) {
    padding-top: 15px;
  }

  @media(max-width:theme('screens.sm')) {
    padding-top: 0;
  }

  &-map {
    position  : absolute;
    inset     : 0;
    background: theme('colors.l2');

    @media(max-width:theme('screens.lg')) {
      position: static;
      height  : 300px;
    }

    @media(max-width:theme('screens.sm')) {
      width      : calc(100% + 30px);
      margin-left: -15px;

    }
  }

  &-content {
    position     : relative;
    border-radius: 32px;
    background   : theme('colors.l3');
    padding      : 48px 45px;
    width        : 454px;
    overflow     : hidden;
    max-width    : 100%;

    @media(max-width:theme('screens.lg')) {
      border-radius: 0;
      padding      : 0;
      margin-top   : 40px;
      position     : static;
    }

  }

  &-content::before,
  &::before {
    content   : '';
    position  : absolute;
    bottom    : 0;
    width     : 100%;
    left      : 0;
    background: linear-gradient(89.97deg, #6557FF 0.02%, #874BFF 17.94%, #AA3FFF 36.38%, #AA3FFF 55.81%, #D14896 75.69%, #FF7C03 92.39%);
    height    : 6px;

  }

  &-content::before {
    @media(max-width:theme('screens.lg')) {
      display: none;
    }
  }

  &::before {
    @media(min-width:theme('screens.lg')) {
      display: none;
    }
  }

}

.reviews {
  &-slider {
    @media(max-width:1500px) {
      // temp

      .slider-next,
      .slider-prev {
        display: none;
      }
    }
  }
}

.dialog-form {
  background           : theme('colors.l3');
  border-radius        : 36px;
  padding              : 16px;
  display              : grid;
  grid-template-columns: 1fr 1fr;
  gap                  : 40px;
  width                : 1020px;
  max-width            : 100%;

  @media(max-width:theme('screens.lg')) {
    width  : 500px;
    padding: 32px 24px;
    display: block;
  }

  @media(max-width:theme('screens.sm')) {
    border-radius: 16px;
  }

  &-image {
    border-radius: 32px;
    overflow     : hidden;

    @media(max-width:theme('screens.lg')) {
      display: none;
    }
  }

  &-content {
    padding-top   : 20px;
    padding-bottom: 20px;

    @media(max-width:theme('screens.lg')) {
      padding: 0;
    }
  }
}

.dialog-policy {
  width        : 1120px;
  max-width    : 100%;
  padding      : 0;
  border-radius: 36px;

  @media(max-width:theme('screens.sm')) {
    border-radius: 16px;
  }

  &-header {
    padding                  : 25px 54px;
    background               : theme('colors.l2');
    border-start-end-radius  : inherit;
    border-start-start-radius: inherit;

    @media(max-width:theme('screens.lg')) {
      padding: 24px 20px;
    }

    @media(max-width:theme('screens.sm')) {
      padding: 24px 15px;
    }
  }

  &-content {
    color  : theme('colors.default / .8');
    padding: 25px 54px;

    @media(max-width:theme('screens.lg')) {
      padding: 24px 20px;
    }

    @media(max-width:theme('screens.sm')) {
      padding  : 24px 15px;
      font-size: 14px;
    }
  }
}

.dialog-info {
  width         : 334px;
  max-width     : 100%;
  border-radius : 36px;
  padding       : 60px 15px;
  display       : flex;
  flex-direction: column;
  align-items   : center;
  gap           : 24px;

  @media(max-width:theme('screens.sm')) {
    border-radius: 16px;
    gap          : 15px;
  }

}

// ------------------

[data-animation] {
  opacity: 0;

  // will-change: transform, opacity;
}

.cases-item-dialog {
  width     : 560px;
  max-width : 100%;
  padding   : 0;
  background: none;
}